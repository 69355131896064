.typeahead-standalone {
    text-align: left;
    //color: #000;
    display: flex;
    flex-direction: column;
    width: 400px;
}

.typeahead-standalone .tt-input {
    z-index: 1;
    //background: transparent
}

.typeahead-standalone .tt-hint {
    cursor: default;
    user-select: none;
    background: #fff;
    color: #767676;
    z-index: 0
}

.typeahead-standalone .tt-list {
    background: #fff;
    z-index: 1000;
    box-sizing: border-box;
    overflow: auto;
    border: 1px solid rgba(50, 50, 50, .6);
    border-radius: 4px;
    box-shadow: 0 10px 30px #0000001a;
    position: absolute;
    max-height: 70vh;
    color: black;
    padding: 8px;
}

.typeahead-standalone .tt-list.tt-hide {
    display: none
}

.typeahead-standalone .tt-list div[class^=tt-] {
    padding: 6px
}

.typeahead-standalone .tt-list .tt-suggestion:hover, .typeahead-standalone .tt-list .tt-suggestion.tt-selected {
    background: #55acee;
    cursor: pointer
}

.typeahead-standalone .tt-list .tt-suggestion .tt-highlight {
    font-weight: 800
}

.typeahead-standalone .tt-list .tt-group {
    background: #eee
}
